import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'app-font',
	templateUrl: './font.component.html',
	styleUrls: ['./font.component.scss']
})
export class FontComponent implements OnInit {
	primaryTextValue = '';
	primaryTextFont = '';
	primaryText = new FormControl();

  availableFonts = [
    'Abby',
    'Adelaide And Georgie',
    'Alafyou Swirly',
    'Alesantria Mane',
    'Allison',
    'Almond',
    'Already Broken Dull',
    'Already Broken Scratch',
    'Amperas',
    'Ancukaseyo',
    'Antiokhia',
    'Antiques',
    'Aprillia Wijaya',
    'Asher Punk',
    'Auto',
    'Autumn',
    'Autumnmarket',
    'Backroads',
    'Balla',
    'Ballerina',
    'Bamella',
    'Bankai',
    'Barnhouse',
    'Barristar',
    'Belly',
    'Bennington Glow',
    'Better',
    'Bigsby Hills',
    'Black',
    'Bloom',
    'Blossom',
    'Bluehonest',
    'Brandy Coffee',
    'Bubbly',
    'Burnfolk',
    'Burnfolk Alt',
    'Butter Tropical',
    'Butter Tropical Script',
    'Byemalkan Italic',
    'Byemalkan Reguler',
    'Cake',
    'Candy',
    'Catarina Devon',
    'Catastrophist',
    'Cayed',
    'Chicken Pie',
    'City',
    'Cosmos Logic',
    'Countryfields',
    'Countrykitchen',
    'Countrykitchenextras',
    'Cozy',
    'Cutie',
    'Cutie Hollow',
    'Deardorf',
    'Deardorf Alternate',
    'Deardorf Drip',
    'Delightful',
    'Democrasweet',
    'Democrasweet Smooth',
    'Diola Gonel',
    'Dreams',
    'Elfira Bold',
    'Elfira Reguler',
    'Elvishwild',
    'Elvishwild Alternate',
    'Elvishwild Alternate Blocky',
    'Elvishwild Blocky',
    'Elvishwild Bottom Shade',
    'Elvishwild Top Shade',
    'Emerald Cole',
    'Fake Nice',
    'Farmland',
    'Farmmarket',
    'Ganghoods',
    'Georgiasunrise',
    'Ginchie',
    'Gingerly',
    'Glitter',
    'Golden Motion',
    'Goodbye Anjing',
    'Goodness Cakes',
    'Good Sunset',
    'Greater Delight',
    'Grimnotes',
    'Grimnotes Alternate',
    'Halliyah',
    'Han Le Tours',
    'Happiness Machine',
    'Happiness Machine Script',
    'Hapsary',
    'Hayward',
    'Herojuana',
    'Her Song',
    'Hogback',
    'Holiday Season',
    'Home',
    'Honest',
    'Honey Butter',
    'Hopeful',
    'Imagine',
    'Itadakimase',
    'Itadakimase Stylistic',
    'Jannatta',
    'Jee Wish',
    'Jenny',
    'Jimmy Collins',
    'Kafarmhouse',
    'Kagomechan',
    'Kikyo',
    'Kutilang',
    'La Maison Brush',
    'Little Comet',
    'Little Comet Bubling',
    'Madina Clean',
    'Madina Clean Ornaments',
    'Madina Clean Alt',
    'Manadis Deadly',
    'Mandalaz Sensei',
    'Mandalaz Sensei Alternate',
    'Mandalaz Sensei Swash',
    'Mandy William',
    'Mango',
    'Marketstreet',
    'Masmuseh',
    'Mayrisand',
    'Melly Honney',
    'Meowtalk ',
    'Meowtalk Alternate',
    'Meowtalk Spilled',
    'Millions Journey',
    'Millions Journey Script',
    'Mismade',
    'Moonlily',
    'Morning',
    'Morning Glow',
    'Mykilove',
    'Mythical Christmas',
    'Mythical Christmas Quirky',
    'Naofumi',
    'Neug Asia',
    'Neug Asia Script',
    'Nicholia',
    'Nurani',
    'Oceanic Cocktail',
    'Ocean Sunshine',
    'Oliver',
    'Omniface',
    'Onigashima',
    'Orion Chalk Scratch',
    'Orion Chalk Solid',
    'Orleymore',
    'Ostrich',
    'Owbeirak Script',
    'Owbeirak Serif',
    'Petter Secret',
    'Pretty',
    'Quarts Pachino',
    'Quesited',
    'Quiet Sickless',
    'Qunka',
    'Rainbow',
    'Raya Feast',
    'Rayleigh',
    'Realitta',
    'Rextions',
    'Rightly',
    'Road',
    'Romance',
    'Rose',
    'Roseandlavender',
    'Rust',
    'Santhia',
    'Shoreline',
    'Signaday',
    'Simple',
    'Sing Buneng Bae',
    'Skinny',
    'Soul Doubt',
    'Soul Doubt Alternate',
    'South Paris',
    'Standard Jeglek',
    'Star Whisper',
    'Sunday',
    'Sundaymorning',
    'Sunny Bunny',
    'Sunny Bunny Alternate',
    'Sunshine',
    'Sunshinemarket',
    'Sydney Tears',
    'Testimony',
    'The August',
    'The Wild Chaos',
    'The Wild Chaos Distressed',
    'The Wild Chaos Stamped',
    'Thinna Bell',
    'Tokyosign',
    'Twist Jelly',
    'Twist Jelly Slab',
    'Uhudscript',
    'Welluxx',
    'Wishbone Tea',
    'Xwisth',
    'Yel Yeah',
    'Youth Richfield',
    'Youth Richfield Alternates',
    'Youth Richfield Swash'
  ]

	onTextChange(event) {
		this.primaryTextValue = event.target.value;
	}

	constructor() {}

	ngOnInit() {}
}
